<template>
  <div class="container py-4 py-lg-5">
    <StudentTransferModal
      :showModal="showModalTransfer"
      :student="student"
      @setTransferring="setTransferring"
      @transferModalClosed="showModalTransfer = false"
      @fetchHistory="fetchHistory"
      @markGroupForRefresh="markGroupForRefresh"
    />

    <div class="row">
      <div class="col-12 col-md-6 col-xl-7">
        <header class="text-contrast mb-4 mb-lg-5">
          <img v-if="student.Image" :src="student.Image" class="float-right d-none d-sm-block img-thumbnail bg-light shadow mb-4 mb-lg-5 ml-2" style="max-height: 10em; max-width: 40%" :alt="`Foto van ${student.Name}`"/>
          <div class="mb-3" v-if="isCustomerPortal || !isIsolated">
            <b-button variant="primary" v-if="isCustomerPortal" :to="{ name: 'home' }">
              <font-awesome-icon icon="angle-left" fixed-width/>
              {{ $t("context.overview") }}
            </b-button>
            <b-button variant="primary" v-else-if="!isIsolated" :to="{ name: 'group' }">
              <font-awesome-icon icon="angle-left" fixed-width/>
              {{ $t("context.group") }}
            </b-button>
          </div>

          <vue-content-loading :speed="1" secondary="#d0d0d0" :height="105" :width="825" style="opacity: 0.35" v-if="showPlaceholder">
            <rect width="400" height="50" x="0" y="10" ry="25" rx="25"></rect>
            <rect width="150" height="20" x="0" y="80" ry="10" rx="10"></rect>
          </vue-content-loading>

          <div v-else>
            <h1 class="display-4 student-name">{{ student.Name || 'Placeholder' }}</h1>
            <div v-if="!isCustomerPortal" class="col-lg-7 p-0">
              <p class="lead">
                <span v-if="student.SkillCategory && student.SkillCategory.Name">
                  {{ student.SkillCategory.Name }}
                </span>
                <span v-else-if="student.SkillCategoryID">
                  {{ $t('context.swimlevel') }} {{ student.SkillCategoryID }}
                </span>

                <span v-if="groupNames" class="mr-2">&bullet; {{ groupNames }}</span>

                <b-badge class="text-danger" variant="light" v-if="typeof student.ValidAccessYN !== 'undefined' && !student.ValidAccessYN">
                  <font-awesome-icon icon="ban" fixed-width/>
                  {{ $t('context.lesson_card') }}
                </b-badge>
              </p>
            </div>
          </div>
        </header>

        <section class="mb-3">
          <vue-content-loading :speed="1" secondary="#d0d0d0" :height="800" :width="825" style="opacity: 0.35" v-if="showPlaceholder">
            <rect width="825" height="650" x="0" y="0" ry="5" rx="5"></rect>
          </vue-content-loading>
          <div v-else-if="!isCustomerPortal">
            <table class="table table-bordered table-hover bg-white rounded overflow-hidden" v-if="skills.length" >
              <thead class="table-light bg-light">
              <tr>
                <th>{{ $t("context.skill") }}</th>
                <th>{{ $t("context.skill_control") }}</th>
              </tr>
              </thead>

              <tbody>
              <tr :key="skill.SkillID" v-for="(skill) in skills">
                <td style="vertical-align: middle">
                  <skill-link :skill="skill" placement="right"/>
                </td>
                <td>
                  <skill-rating :value="skill.Rating" :person-id="student.PersonID" :skill-id="skill.SkillID" :max-rating="_meta.LVS__SKILL__MAX_RATING" @change="updateSkill(skill.SkillID, $event)"/>
                </td>
              </tr>
              </tbody>

              <tfoot>
              <tr class="table-light bg-light">
                <th>{{ $t("context.total_progress") }}</th>
                <th><skill-rating :value="avgRating" :max-rating="_meta.LVS__SKILL__MAX_RATING" :disabled="true"/></th>
              </tr>
              </tfoot>
            </table>
          </div>
          <div class="row d-block" v-else-if="isCustomerPortal">
            <student-course-list :insights="student.CustomerInsights" :groups="student.ActivityGroup" :person-id="student.PersonID" :attendance="this.student.Person_SubscriptionAgenda" />
          </div>
        </section>
      </div>

      <div class="col" v-if="showPlaceholder">
        <vue-content-loading :speed="1" secondary="#d0d0d0" :height="1000" :width="400" style="opacity: 0.35" v-if="showPlaceholder">
          <rect width="400" height="350" x="0" y="0" ry="5" rx="5"></rect>
          <rect width="400" height="250" x="0" :y="350 + 15" ry="5" rx="5"></rect>
          <rect width="400" height="350" x="0" :y="350 + 15 + 250 + 15" ry="5" rx="5"></rect>
        </vue-content-loading>
      </div>

      <div class="col-12 col-md-6 col-xl-5" v-else>
        <b-card bg-variant="light" class="mb-3 card-hover" v-for="teacher in teachers" :key="'teacher_'+teacher.EmployeeID">
          <span slot="header"><font-awesome-icon icon="user" fixed-width/> {{ $t('context.teacher') }}</span>

          <div class="row">
            <div class="col col-4 col-md-3">
              <b-img fluid class="mr-3 image-100" :src="$store.state.info.wwwUrl + teacher.Image" :alt="teacher.Name" rounded-circle/>
            </div>
            <div class="col col-8 col-md-9">
              <h5 class="card-title">{{ teacher.Name }}{{ teacher.Name2 ? ' '+teacher.Name2 : '' }}</h5>
              <p class="card-text">{{ groupNameOfTeacher(teacher.EmployeeID) }}</p>
            </div>
          </div>
        </b-card>

        <b-card bg-variant="light" class="mb-3 card-hover">
          <span slot="header"><font-awesome-icon icon="circle-info" fixed-width/> {{ $t("context.information") }}</span>

          <dl class="card-text">
            <dt v-if="student.Email && student.Email.length > 3 && !isCustomerPortal">
              <font-awesome-icon icon="envelope" fixed-width/>
              {{ $t('common.email_address') }}
            </dt>
            <dd v-if="student.Email && student.Email.length > 3 && !isCustomerPortal"><a :href="`mailto:${student.Email}`">{{ student.Email }}</a></dd>

            <dt v-if="student.Phone && student.Phone.length > 3 && !isCustomerPortal">
              <font-awesome-icon icon="phone" fixed-width/>
              {{ $t("context.phone_number") }}
            </dt>
            <dd v-if="student.Phone && student.Phone.length > 3 && !isCustomerPortal"><a :href="`tel:${student.Phone}`">{{ student.Phone }}</a></dd>

            <dt v-if="birthday">
              {{ $t("common.date_of_birth") }}
            </dt>
            <dd v-if="birthday" class="mb-3">{{ birthday }}</dd>

            <dt v-if="student.Teaser">
              {{ $t("context.particularities") }}
              <font-awesome-icon icon="bell" class="text-warning" fixed-width/>
            </dt>
            <dd v-if="student.Teaser" class="mb-3">{{ student.Teaser }}</dd>

<!--            <dt>Gaat verder na afzwemmen</dt>-->
<!--            <dd v-if="student.ContinueToNextSkillCategoryYN"><font-awesome-icon icon="check" fixed-width class="text-success"/> Ja</dd>-->
<!--            <dd v-else><font-awesome-icon icon="xmark" fixed-width class="text-danger"/> Nee</dd>-->

<!--            <dt v-if="student.Description && !isCustomerPortal">Notitie</dt>-->
<!--            <dd v-if="student.Description && !isCustomerPortal" v-html="stripTags(student.Description)"></dd>-->

            <h6>{{ $t("context.presence") }}</h6>

            <table class="table" style="font-size: .8rem;">
              <thead>
              <tr>
                <th scope="col">{{ $t("context.level") }}</th>
                <th scope="col">{{ $t("context.moments_present") }}</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(attendance, index) in student.Attendance" :key="index">
                  <th scope="row" class="hyphens">{{ attendance['SkillCategory.Name'] }}</th>
                  <td>{{ attendance.SumPresent }} / {{ parseInt(attendance.SumPresent) + parseInt(attendance.SumAbsent) }} ({{ getAttendancePercentage(attendance) }}% {{ $t("context.present_lower") }})
                    <div v-if="!isCustomerPortal">
                        <div class="d-inline-block" v-if="attendance['SkillCategory.TimeDescription'] && attendance['SkillCategory.TimeDescription'] !== '0'">
                          <font-awesome-icon fixed-width
                              icon="circle"
                              :class="{
                                'text-success': parseInt(attendance.SumPresent) <= parseInt(attendance['SkillCategory.TimeDescription']),
                                'text-danger': parseInt(attendance.SumPresent) > parseInt(attendance['SkillCategory.TimeDescription'])
                              }"/>
                            {{ attendance['SkillCategory.TimeDescription'] == 1 ? $t("context.average_lessons_needed") : $t("context.average_lessons_needed_other", { count: attendance['SkillCategory.TimeDescription']}) }}
                          <div class="d-inline-block text-danger" v-if="parseInt(attendance['SumPresent']) > parseInt(attendance['SkillCategory.TimeDescription'])">
                            {{ $t("context.progress_slower_than_normal") }}
                          </div>
                        </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </dl>
        </b-card>

        <div class="position-relative" v-if="banners.LVSCTABANNER01">
          <b-img thumbnail fluid class="w-100 mb-3 bg-light" :src="banners.LVSCTABANNER01.Image" :alt="banners.LVSCTABANNER01.BannerName"></b-img>
          <a :href="banners.LVSCTABANNER01.Url" target="_blank" class="stretched-link"></a>
        </div>

        <b-card no-body bg-variant="light" class="mb-3 card-hover" v-if="isCustomerPortal">
          <span slot="header"><font-awesome-icon icon="gear" fixed-width/> {{ $t("common.actions") }}</span>

          <div class="card-body" v-if="student.RescheduleAllowedYN">
            <p class="mb-0"><small>{{ $t("context.actions_info") }}</small></p>
          </div>

          <b-list-group flush>
            <b-list-group-item button v-b-modal="_id('modal-absent')">
              <font-awesome-icon :icon="transferring ? 'circle-notch' : ['fad', 'briefcase-medical']" :spin="transferring" class="text-muted mr-1"/>
              {{ $t("context.submit_absence") }}
            </b-list-group-item>

            <b-list-group-item button v-b-modal="_id('modal-reschedule')" :disabled="!rescheduleAvailable" v-if="student.RescheduleAllowedYN">
              <font-awesome-icon :icon="transferring ? 'circle-notch' : ['fad', 'calendar-check']" :spin="transferring" class="text-muted mr-1"/>
              {{ $t("context.plan_make_up_lesson") }}

              <span v-if="!rescheduleAvailable"></span>
              <b-badge variant="danger" class="mr-1" v-if="!rescheduleAvailable">
                <font-awesome-icon size="lg" icon="ban" fixed-width/>
                {{ $t("context.not_possible") }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>

          <!-- Modal Components -->
          <b-modal :id="_id('modal-absent')"
            v-model="showModalAbsent"
            @ok="setAbsent"
            @cancel="absenceAgenda = absenceReason = null"
            ok-variant="success"
            :ok-title="$t('actions.send')"
            cancel-variant="outline-dark"
            :cancel-title="$t('actions.cancel')"
            :ok-disabled="absenceAgenda === null"
          >
            <span slot="modal-title"><font-awesome-icon :icon="['fad', 'briefcase-medical']" fixed-width/>&nbsp;{{ $t('context.report_absence') }}</span>

            <b-form-group :label="$t('context.report_absence_moment')">
              <b-select v-model="absenceAgenda" :options="upcomingClasses" required></b-select>
              <small v-html="absentFieldNotice" class="text-muted"></small>
            </b-form-group>

            <b-form-group :label="$t('context.report_absence_reason')" :description="$t('context.report_absence_visibility')">
              <b-textarea v-model="absenceReason"></b-textarea>
            </b-form-group>
          </b-modal>

          <b-modal :id="_id('modal-reschedule')"
            v-model="rescheduleModal.showModalReschedule"
            @ok="reschedule"
            @cancel="rescheduleAgenda = null"
            ok-variant="success"
            :ok-title="$t('common.schedule')"
            cancel-variant="outline-dark"
            :cancel-title="$t('actions.cancel')"
            :ok-disabled="rescheduleAgenda === null"
            v-if="rescheduleAvailable"
          >
            <span slot="modal-title"><font-awesome-icon :icon="['fad', 'calendar-check']" fixed-width/>&nbsp;{{ $t('context.plan_catch_up_lesson') }}</span>

            <b-form-group :label="$t('context.catch_up_level_question')" :description="$t('context.catch_up_level_description')">
              <b-select v-model="rescheduleModal.selectedSkillCategory" :options="rescheduleSkillCategoryOptions" :disabled="fetchingOther" required></b-select>
            </b-form-group>

            <font-awesome-icon :icon="['fad', 'spinner-third']" fixed-width spin class="text-center w-100" v-if="fetchingOther && rescheduleModal.selectedSkillCategory"/>

            <b-form-group :label="$t('context.catch_up_when')" :description="$t('context.catch_up_when_description')" v-if="rescheduleModal.selectedSkillCategory && !fetchingOther">
              <b-select v-model="rescheduleAgenda" :options="rescheduleOptions" :disabled="!rescheduleModal.selectedSkillCategory" required></b-select>
            </b-form-group>
          </b-modal>
        </b-card>

        <b-card no-body bg-variant="light" class="mb-3 card-hover" v-if="isCustomerPortal && student.CatchUpGroups.length">
          <span slot="header"><font-awesome-icon :icon="['fad', 'calendar-check']" fixed-width/>&nbsp;{{ $t('context.planned_catch_up_lessons') }}</span>

          <b-list-group flush>
            <b-list-group-item
              v-for="(group, index) in student.CatchUpGroups"
              :key="group.ActivityGroupId+'_'+index"
            >
                <b-media no-body class="mb-1">
                  <b-media-aside class="py-1">
                    <font-awesome-icon icon="location-dot" fixed-width/>
                  </b-media-aside>
                  <b-media-body>
                    <span>{{ group.WarehouseName }}</span>
                  </b-media-body>
                </b-media>
                <div :key="value.id" v-for="value in group.PlannedMoments">
                  <b-media no-body class="mb-1">
                    <b-media-aside class="py-1">
                      <font-awesome-icon icon="calendar" fixed-width/>
                    </b-media-aside>
                    <b-media-body>
                      <span>{{ formatDate(value.StartDate) || '' }}</span>
                    </b-media-body>
                  </b-media>
                </div>
              </b-list-group-item>
          </b-list-group>
        </b-card>

        <b-card no-body bg-variant="light" class="mb-3 card-hover" v-else-if="!isIsolated">
          <span slot="header"><font-awesome-icon icon="gear" fixed-width/>&nbsp;{{ $t('common.actions') }}</span>

          <!-- Modal Component -->
          <b-modal :id="_id('modal-skill-category')"
                   v-model="showModalSkillCategory"
                   @ok="modifySkillCategory"
                   @show="fetchSkillCategories"
                   ok-variant="success"
                   :ok-title="$t('actions.save')"
                   cancel-variant="outline-dark"
                   :cancel-title="$t('actions.cancel')"
                   :ok-disabled="modifySkillCategoryTo === null || modifySkillCategoryTo === student.SkillCategoryID"
          >
            <span slot="modal-title"><font-awesome-icon :icon="['far', 'star']" fixed-width/>&nbsp;{{ $t('context.change_level') }}</span>

            <b-form-group :label="$t('context.change_level_student_question')">
              <b-select v-model="modifySkillCategoryTo" :disabled="modifySkillCategoryOptions.length === 0" :options="modifySkillCategoryOptions"></b-select>
            </b-form-group>
          </b-modal>

          <!-- Modal Component -->
          <b-modal :id="_id('modal-finish')"
                   v-model="showModalFinish"
                   @ok="readyToFinish"
                   ok-variant="success"
                   :ok-title="$t('common.agree')"
                   cancel-variant="outline-dark"
                   :cancel-title="$t('actions.cancel')"
          >
            <span slot="modal-title"><font-awesome-icon icon="check" fixed-width/>&nbsp;{{ $t('context.ready_to_swim_off') }}</span>
            <p>{{ $t('context.ready_to_swim_off_prompt') }}</p>
          </b-modal>

          <b-list-group flush>
            <b-list-group-item button @click="showModalTransfer = true" :disabled="!transferable">
              <font-awesome-icon :icon="transferring ? 'circle-notch' : transferable ? 'angles-right' : 'check'" :spin="transferring" fixed-width/>
              {{ transferable || transferring ? $t('common.move') : $t('common.moved') }}
            </b-list-group-item>

            <b-list-group-item button v-b-modal="_id('modal-skill-category')" :disabled="modifyingSkillCategory">
              <font-awesome-icon :icon="modifyingSkillCategory ? 'circle-notch' : ['far', 'star']" :spin="modifyingSkillCategory" fixed-width/>
              {{ 'Niveau wijzigen' }}
            </b-list-group-item>

            <b-list-group-item button v-b-modal="_id('modal-finish')" :disabled="!finishable || student.ReadyForFinishedActivityGroupYN">
              <font-awesome-icon :icon="finishing ? 'circle-notch' : finishable ? ['fad', 'person-swimming'] : 'check'" :spin="finishing" fixed-width/>
              {{ !student.ReadyForFinishedActivityGroupYN ? $t('context.finish_course') : $t('context.finish_course_accepted') }}
            </b-list-group-item>
          </b-list-group>
        </b-card>

        <b-card bg-variant="light" class="mb-3 card-hover" v-if="!isCustomerPortal">
          <span slot="header"><font-awesome-icon icon="clock-rotate-left" fixed-width/>&nbsp;{{ $t('common.history') }}</span>
          <b-form class="mb-3" @submit.prevent="addComment" v-if="$store.getters['auth/userType'] === 'User'">
            <b-form-group :label="$t('context.new_intern_comment')">
              <b-textarea :state="newCommentState" v-model="newComment" rows="3"/>
            </b-form-group>
            <b-form-group class="text-right">
              <b-button type="submit" variant="success">{{ $t('actions.save') }}</b-button>
            </b-form-group>
          </b-form>

          <ul class="list-unstyled overflow-auto mb-0" style="max-height: 30em">
            <transition-group name="comment">
              <b-media tag="li" :key="`history-${i}`" v-for="(historyItem, i) in privateHistory" :class="{ 'mb-4': i < privateHistory.length-1, 'text-muted font-italic': historyItem.Type === 'Empty' || historyItem.Type === 'Loading' }">
                <font-awesome-icon slot="aside" :icon="getHistoryIcon(historyItem)" class="text-muted" size="2x"/>

                <div v-if="historyItem.Type === 'Review'">
                  <h5 class="my-0">{{ historyItem.CreatedBy }}</h5>
                  <small class="text-muted mb-1">{{ (new Date(historyItem.CreatedDate)).toLocaleDateString('nl-NL', {day: 'numeric', month: 'short', year: 'numeric'}) }} {{ (new Date(historyItem.CreatedDate)).toLocaleTimeString('nl-NL') }}</small>
                  <p class="mb-0">{{ historyItem.Text }}</p>
                </div>

                <div v-else-if="historyItem.Type === 'GroupStart'">
                  <p class="mb-0">{{ historyItem.Text }}</p>
                  <p class="mb-0">
                    <small class="text-muted mb-1">{{ (new Date(historyItem.CreatedDate)).toLocaleDateString('nl-NL', {day: 'numeric', month: 'short', year: 'numeric'}) }}</small>
                  </p>
                </div>

                <div v-else-if="historyItem.Type === 'GroupEnd'">
                  <p class="mb-0">{{ historyItem.Text }}</p>
                  <p class="mb-0">
                    <small class="text-muted mb-1">{{ (new Date(historyItem.CreatedDate)).toLocaleDateString('nl-NL', {day: 'numeric', month: 'short', year: 'numeric'}) }}</small>
                  </p>
                </div>
              </b-media>
            </transition-group>
          </ul>
        </b-card>

        <b-card bg-variant="light" class="mb-3 card-hover">
          <span slot="header"><font-awesome-icon icon="comment" fixed-width/> {{ isCustomerPortal ? $t('context.teacher_messages') : $t('context.parent_messages') }}</span>
          <b-form class="mb-3" @submit.prevent="addMessage" v-if="!isCustomerPortal">
            <b-form-group :label="$t('context.post_message_parents')">
              <b-textarea :state="newMessageState" v-model="newMessage" rows="3"/>
            </b-form-group>
            <b-form-group class="text-right">
              <b-button type="submit" variant="primary">{{ $t("actions.send") }}&nbsp;<font-awesome-icon icon="paper-plane" fixed-width/></b-button>
            </b-form-group>
          </b-form>

          <ul class="list-unstyled overflow-auto mb-0" style="max-height: 30em">
            <transition-group name="comment" mode="out-in">
              <b-media tag="li" :key="`history-${i}`" v-for="(historyItem, i) in publicHistory" :class="{ 'mb-4': i < publicHistory.length-1, 'text-muted font-italic': historyItem.Type === 'Empty' || historyItem.Type === 'Loading' }">
                <font-awesome-icon slot="aside" :spin="fetchingHistory" :icon="getHistoryIcon(historyItem)" class="text-muted" size="2x"/>

                <div v-if="historyItem.Type === 'Review'">
                  <h5 class="my-0">{{historyItem.CreatedBy}}</h5>
                  <small class="text-muted mb-1">{{ (new Date(historyItem.CreatedDate)).toLocaleDateString('nl-NL', {day: 'numeric', month: 'short', year: 'numeric'}) }} {{ (new Date(historyItem.CreatedDate)).toLocaleTimeString('nl-NL')}}</small>
                  <p class="mb-0">
                    <span :key="i" v-for="(line, i) in splitByNewLine(historyItem.Text)">{{ line }} <br></span>
                  </p>
                </div>

                <div v-else-if="historyItem.Type === 'GroupStart'">
                  <p class="mb-0">{{ historyItem.Text }}</p>
                  <p class="mb-0">
                    <small class="text-muted mb-1">{{ (new Date(historyItem.CreatedDate)).toLocaleDateString('nl-NL', {day: 'numeric', month: 'short', year: 'numeric'}) }} {{ (new Date(historyItem.CreatedDate)).toLocaleTimeString('nl-NL')}}</small>
                  </p>
                </div>

                <div v-else-if="historyItem.Type === 'Empty' || historyItem.Type === 'Loading'">
                  <p class="mb-0">{{ historyItem.Text }}</p>
                </div>

                <div v-else>
                  <h5 class="my-0">{{ historyItem.Text }}</h5>
                </div>
              </b-media>
            </transition-group>
          </ul>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { protectedApi, studentTracking } from '@/http'

import formatDateTime from '@/util/dateTime/formatDateTime'
import StudentCourseList from '../components/StudentCourseList'
import SkillRating from '@/components/SkillRating'
import SkillLink from '@/components/SkillLink'
import StudentTransferModal from '@/components/StudentTransferModal'
import { mapActions } from 'vuex'

export default {
  components: { StudentCourseList, SkillRating, SkillLink, StudentTransferModal },
  data () {
    return {
      student: {},
      newComment: '',
      newCommentState: null,
      newMessage: '',
      newMessageState: null,
      history: [],
      fetchingHistory: false,
      fetchingOther: false,
      skillCategories: [],
      transferring: false,
      modifyingSkillCategory: false,
      modifySkillCategoryTo: null,
      absenceAgenda: null,
      absenceReason: null,
      rescheduleAgendaList: [],
      attendance: [],
      rescheduleAgenda: null,
      finishing: false,
      showModalAbsent: false,
      rescheduleModal: {
        showModalReschedule: false,
        selectedSkillCategory: null
      },
      showModalTransfer: false,
      showModalSkillCategory: false,
      showModalFinish: false
    }
  },

  computed: {
    diffText () {
      if (this.student && this.student.ActivityGroup) {
        if (this.student.ActivityGroup.length <= 1 || !this.isCustomerPortal) {
          return this.$t('context.this_level')
        } else {
          return this.$t('context.current_levels')
        }
      } else {
        return this.$t('no_level')
      }
    },
    groupNames () {
      return this.studentGroups.map(group => this.activityGroupName(group)).join(' / ')
    },
    rescheduleAvailable () {
      return this.student.RescheduleQuantity > 0
    },
    rescheduleSkillCategoryOptions () {
      const options = [this.$t('context.make_choice')]
      for (const key in this.student.ActivityGroup) {
        const value = this.student.ActivityGroup[key]['PersonActivityGroupSkillCategoryID']
          ? this.student.ActivityGroup[key]['PersonActivityGroupSkillCategoryID']
          : this.student.ActivityGroup[key]['ActivityGroupID']
        options.push({
          value: value,
          text: this.student.ActivityGroup[key]['SkillCategory']['Name'],
          disabled: this.student.ActivityGroup[key].RescheduleQuantity < 1
        })
      }
      return options
    },
    _meta () {
      return this.student._meta
    },
    showPlaceholder () {
      return this.studentLoading
    },
    isIsolated () {
      return this.$route.name === 'person'
    },
    currentGroup () {
      return this.$store.getters['groups/all'].find(group => {
        return group.SubscriptionAgendaID === this.$route.params.agenda_id
      })
    },
    birthday () {
      return (new Date(this.student.DateOfBirth))
        .toLocaleDateString(
          'nl-NL',
          { year: 'numeric', month: 'long', day: 'numeric' }
        )
    },
    transferable () {
      if (this.transferring) return false

      if (!this.currentGroup) return false

      if (this.student.ActivityGroup && Array.isArray(this.student.ActivityGroup) && this.student.ActivityGroup.length > 0) {
        return this.student.ActivityGroup.reduce((inCurrentGroup, group) => {
          return inCurrentGroup || group.ActivityGroupID === this.currentGroup.ActivityGroupID
        }, false)
      }

      return false
    },
    finishable () {
      return !this.finishing && (typeof this.student.OnFinishedListYN === 'undefined' || this.student.OnFinishedListYN < 1)
    },
    skills () {
      return this.student.items || []
    },
    privateHistory () {
      return this.history.filter(history => {
        return history.Type !== 'Review' || (history.Type === 'Review' && parseInt(history.ReviewTypeID) === 1)
      }).sort((a, b) => {
        const startA = new Date(a.CreatedDate)
        const startB = new Date(b.CreatedDate)
        if (startA < startB) return 1
        if (startA > startB) return -1
        if (a.Type === 'GroupEnd' && b.Type === 'GroupStart') return 1
        if (a.Type === 'GroupStart' && b.Type === 'GroupEnd') return -1
        return 0
      })
    },
    publicHistory () {
      let history = this.history.filter(history => {
        return history.Type === 'Review' && parseInt(history.ReviewTypeID) === 2
      })
      if (history.length === 0) {
        if (this.fetchingHistory) {
          history = [{
            Type: 'Loading',
            Text: this.$t('context.retrieve_messages')
          }]
        } else {
          history = [{
            Type: 'Empty',
            Text: this.$t('context.no_messages')
          }]
        }
      }
      return history
    },
    modifySkillCategoryOptions () {
      let options = []

      this.skillCategories.map(sc => {
        options.push({
          value: sc.SkillCategoryID,
          text: sc.Name,
          sequence: sc.SequenceNumber
        })
      })

      return options.sort((a, b) => {
        if (a.sequence < b.sequence) return -1
        if (a.sequence > b.sequence) return 1
        return 0
      })
    },
    studentGroups () {
      return this.student.ActivityGroup || []
    },
    studentGroupIDs () {
      return this.studentGroups.map(group => group.ActivityGroupID)
    },
    upcomingClasses () {
      let options = this.studentGroups
        // (1) Combine SubscriptionAgenda of all ActivityGroups
        .reduce((agenda, group) => agenda.concat(group.SubscriptionAgenda || []), [])
        // (2) Sort chronologically
        .sort((a, b) => new Date(a.StartDate) - new Date(b.StartDate))
        // (3) Map SubscriptionAgenda to valid options for <b-select>
        .map(agenda => {
          let textParts = [
            `${(new Date(agenda.StartDate)).toLocaleString('nl-NL', {
              weekday: 'short',
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: '2-digit'
            })}, ${(this.studentGroups.find(g => g.ActivityGroupID === agenda.ActivityGroupID) || {}).Name || agenda.ActivityGroupID}`
          ]

          const alreadyCanceled = this.attendance
            .filter(a => !!a.CancelYN)
            .map(a => a.SubscriptionAgendaID)
            .includes(agenda.SubscriptionAgendaID)

          if (alreadyCanceled) {
            textParts.push(this.$t('context.already_signed_off'))
          }

          return {
            value: agenda.SubscriptionAgendaID,
            text: textParts.join(' '),
            disabled: alreadyCanceled
          }
        })

      options.unshift({ value: null, text: '', disabled: true })

      return options
    },
    rescheduleOptions () {
      let options = this.rescheduleAgendaList
        .filter(agenda => !this.studentGroupIDs.includes(agenda.ActivityGroupID))
        .map(agenda => {
          let textParts = [
            `${(new Date(agenda.StartDate)).toLocaleString('nl-NL', {
              weekday: 'short',
              day: 'numeric',
              month: 'short',
              hour: 'numeric',
              minute: '2-digit'
            })}, ${(agenda.ActivityGroup || {}).Name || agenda.ActivityGroupID}`
          ]

          const alreadyRescheduled = this.attendance
            .filter(a => !!a.RescheduledYN)
            .map(a => a.SubscriptionAgendaID)
            .includes(agenda.SubscriptionAgendaID)

          const groupFull = agenda.MaxEntryQuantity !== null && parseInt(agenda.CurrentEntryQuantity || 0) >= parseInt(agenda.MaxEntryQuantity)

          if (alreadyRescheduled) {
            textParts.push(this.$t('context.already_signed_up'))
          } else if (groupFull) {
            textParts.push(this.$t('context.full'))
          }

          return {
            value: agenda.SubscriptionAgendaID,
            text: textParts.join(' '),
            disabled: alreadyRescheduled || groupFull
          }
        })

      options.unshift({ value: null, text: '', disabled: true })

      return options
    },
    avgRating () {
      if (this.skills.length === 0) {
        return undefined
      }
      let total = this.skills.reduce((total, skill) => {
        if (typeof total === 'undefined') {
          return parseFloat(skill.Rating || '0')
        }
        return total + parseFloat(skill.Rating || '0')
      }, undefined)
      if (typeof total === 'undefined') {
        return total
      }

      const allNullRatings = this.skills.every(skill => skill.Rating === null || skill.Rating === '')
      if (allNullRatings) {
        return null
      }
      return total / this.skills.length
    },
    studentLoading () {
      return Object.values(this.student).length === 0
    },
    banners () {
      return this.$store.state.banners.banners
    },
    absentFieldNotice () {
      if (this.$store.state.standardTexts.standardtexts.LVSCANCELATION) {
        return this.$store.state.standardTexts.standardtexts.LVSCANCELATION.Memo
      } else {
        return this.$t('context.long_absence_message')
      }
    },
    teachers () {
      const teachers = [...new Set(this.student.ActivityGroup.flatMap(group => group.Employee.filter(employee => !!employee.Image)))]
      return teachers
    }
  },

  methods: {
    ...mapActions('groups', ['markGroupForRefresh']),

    fetchStudent () {
      studentTracking.get(this.$route.path)
        .then(response => {
          this.student = response.data
        })

      studentTracking.get(`${this.$route.path}/subscription-agenda`)
        .then(response => {
          this.attendance = (response.data || {}).items || []
        })
    },
    getAttendancePercentage (attendance) {
      let sumPresent = parseInt(attendance.SumPresent)
      let sumAbsent = parseInt(attendance.SumAbsent)

      let percentage = 0
      // prevent dividing by zero resulting in NaN
      if (sumAbsent + sumPresent !== 0) {
        percentage = (100 * sumPresent) / (sumAbsent + sumPresent)
      }
      return Math.round(percentage)
    },
    fetchSkillCategories () {
      if (this.isIsolated) {
        return
      }

      studentTracking.get(`subscription-agenda/${this.$route.params.agenda_id || 'current'}/skill-categories`)
        .then(response => {
          this.skillCategories = response.data.items
        })
    },
    async fetchRescheduleAgendaList () {
      this.fetchingOther = true

      const ActivityID = this.studentGroups.reduce((id, group) => id || group.ActivityID, null)
      const WarehouseID = this.studentGroups.reduce((id, group) => id || group.WarehouseID, null)
      const FinishedListYN = this.studentGroups.reduce((id, group) => id || group.FinishedListYN, null)

      const list = []
      let total = 0
      let page = 1
      let limit
      do {
        let { data } = await protectedApi.get('subscription-agenda', {
          params: {
            page,
            limit,
            SkillCategoryID: this.rescheduleModal.selectedSkillCategory,
            // Only send ActivityID if SkillCategoryID is unknown
            ActivityID: this.student.SkillCategoryID ? undefined : ActivityID,
            WarehouseID,
            FinishedListYN
          }
        })
        list.push(...data.items || [])
        total = data._meta.total
        page = data._meta.page + 1
        limit = data._meta.per_page
      } while (total > list.length)

      this.rescheduleAgendaList = list
      this.fetchingOther = false
    },
    getHistoryIcon (history) {
      switch (history.Type) {
        case 'Review':
          return 'quote-left'
        case 'GroupStart':
          return 'angles-right'
        case 'Empty':
          return 'comment-slash'
        default:
          return 'life-ring'
      }
    },
    addComment () {
      if (!this.newComment) {
        this.newCommentState = false
      } else {
        this.history.unshift({
          Type: 'Review',
          CreatedBy: this.$store.getters['auth/userId'],
          CreatedDate: new Date(),
          Text: this.newComment,
          ReviewTypeID: 1
        })

        studentTracking.post(this.$route.path + '/reviews', {
          Review: this.newComment
        })
          .catch(error => {
            this.history.shift()
            this.showErrorToast(error)
          })

        this.newComment = ''
        this.newCommentState = null
      }
    },
    addMessage () {
      if (!this.newMessage) {
        this.newMessageState = false
      } else {
        this.history.unshift({
          Type: 'Review',
          CreatedBy: this.$store.getters['auth/userId'],
          CreatedDate: new Date(),
          Text: this.newMessage,
          ReviewTypeID: 2
        })

        studentTracking.post(this.$route.path + '/reviews', {
          Review: this.newMessage,
          ReviewTypeID: 2
        })
          .catch(error => {
            this.history.shift()
            this.showErrorToast(error)
          })
      }

      this.newMessage = ''
      this.newMessageState = null
    },
    setTransferring (bool) {
      this.transferring = bool
    },
    readyToFinish () {
      this.finishing = true
      studentTracking.put(`subscription-agenda/${this.$route.params.agenda_id}/persons/${this.student.PersonID}`, {
        ReadyForNextYN: true
      })
        .then(() => {
          this.showSuccessToast(`${this.student.Name} is succesvol goedgekeurd om af te zwemmen`)
          this.student.OnFinishedListYN = true
          this.markGroupForRefresh({ group: this.$route.params.agenda_id })
          this.fetchStudent()
        })
        .catch(err => {
          this.showErrorToast(err)
        })
        .finally(() => {
          this.finishing = false
        })
    },
    modifySkillCategory () {
      this.modifyingSkillCategory = true
      studentTracking.put(`subscription-agenda/${this.$route.params.agenda_id}/persons/${this.student.PersonID}`, {
        SkillCategoryID: this.modifySkillCategoryTo
      })
        .then(() => {
          this.showSuccessToast(`Niveau van ${this.student.Name} is succesvol gewijzigd`)
          this.markGroupForRefresh({ group: this.$route.params.agenda_id })
          this.fetchStudent()
          this.fetchHistory()
        })
        .catch(err => {
          this.showErrorToast(err)
        })
        .finally(() => {
          this.modifyingSkillCategory = false
        })
    },
    fetchHistory () {
      this.fetchingHistory = true
      studentTracking.get(this.$route.path + '/history')
        .then(response => {
          this.history = response.data.items || []
          this.fetchingHistory = false
        })
    },
    stripTags (str) {
      return str.replace(/<[^>]*>?/g, '')
    },
    setAbsent () {
      if (!this.absenceAgenda) {
        return
      }

      let promise

      if (!this.isCustomerPortal) {
        promise = studentTracking.put(`subscription-agenda/${this.absenceAgenda}/persons/${this.student.PersonID}`, {
          PresentYN: false,
          CancelYN: true,
          Reason: this.absenceReason
        })
      } else {
        promise = studentTracking.put(`/persons/${this.student.PersonID}`, {
          SubscriptionAgendaID: this.absenceAgenda,
          PresentYN: false,
          CancelYN: true,
          Reason: this.absenceReason
        })
      }

      promise
        .then(() => {
          this.absenceAgenda = this.absenceReason = null
          return this.fetchStudent()
        })
        .catch(err => this.showErrorToast(err))
    },
    formatDate (date) {
      if (date) {
        return formatDateTime(date, {
          dateStyle: 'long',
          timeStyle: 'short'
        })
      }

      return false
    },
    reschedule () {
      if (!this.rescheduleAgenda) {
        return
      }

      studentTracking
        .post(`/persons/${this.student.PersonID}/reschedule`, {
          SubscriptionAgendaID: this.rescheduleAgenda
        })
        .then(() => {
          this.rescheduleAgenda = null
          return this.fetchStudent()
        })
        .catch(err => this.showErrorToast(err))
    },
    updateSkill (skillId, value) {
      const idx = this.student.items.findIndex(skill => skill.SkillID === skillId)
      const skill = this.student.items[idx]
      skill.Rating = value
      skill.CreatedDate = Date.now()
      skill.ModifiedDate = Date.now()

      this.student.items[idx] = skill
    },
    groupNameOfTeacher (teacherID) {
      return this.student.ActivityGroup.find(group => group.EmployeeID === teacherID).Name
    }
  },
  mounted () {
    // DEV: fetch person if 'beforeRouteEnter' did not trigger (e.g. hot reload)
    if (Object.values(this.student).length === 0) {
      this.fetchStudent()
    }

    if (!this.isCustomerPortal) {
      if (this.$store.getters['groups/all'].length === 0) {
        this.$store.dispatch('groups/fetch')
      }

      // this.fetchSkillCategories()

      if (this.student.ActivityGroup && this.student.ActivityGroup.length > 0) {
        this.$store.dispatch('groups/setActiveEmployee', { EmployeeID: this.student.ActivityGroup[0].EmployeeID })
      }
    }

    this.fetchHistory()
  },
  beforeRouteLeave (to, from, next) {
    this.showModalAbsent = false
    this.rescheduleModal.showModalReschedule = false
    this.showModalTransfer = false
    this.showModalSkillCategory = false
    this.showModalFinish = false

    next()
  },
  watch: {
    student (student) {
      this.modifySkillCategoryTo = student.SkillCategoryID
    },
    newComment () {
      this.newCommentState = null
    },
    'rescheduleModal.selectedSkillCategory' () {
      this.fetchRescheduleAgendaList()
    }
  }
}
</script>
