import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import { stripTags } from 'bootstrap-vue/esm/utils/html'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { faPartyHorn, faAngleLeft, faChevronDown, faChevronRight, faUser, faBan, faWhistle, faCalendar, faSyncAlt, faLocationDot, faRotate, faGear, faComment, faCommentSlash, faAngleRight, faAnglesRight, faInfo, faCircleNotch, faClipboardCheck, faLock, faUsers, faMagnifyingGlass, faCircleCheck, faBuilding, faRotateRight, faEnvelope, faCircle, faPhone, faCheck, faXmark, faCircleInfo, faClockRotateLeft, faLifeRing, faPaperPlane, faPencil, faTriangleExclamation, faShareNodes, faUserPlus, faUserSlash, faBell, faArrowLeftLong, faWaterLadder, faClipboardListCheck, faQuoteLeft, faCircleDashed, faChartLineUp, faChartLineDown } from '@fortawesome/pro-solid-svg-icons' // fas
import { faBriefcaseMedical, faCalendarCheck, faEye, faEyeSlash, faClock, faPersonSwimming, faSignOutAlt, faUpRightFromSquare, faSpinnerThird, faCircleExclamation, faSquareCheck, faRightFromBracket, faAlarmClock } from '@fortawesome/pro-duotone-svg-icons' // fad
import { faStar } from '@fortawesome/pro-regular-svg-icons'

import Datepicker from 'vuejs-datepicker'
import VueContentLoading from 'vue-content-loading'
import VueConfetti from 'vue-confetti'
import VueHtml2Canvas from 'vue-html2canvas'

import App from './App.vue'
import router from './router'
import store from './store'
import './i18n'
// Add all imported FontAwesome icons
library.add(faPartyHorn, faAngleLeft, faChevronDown, faChevronRight, faUser, faBan, faWhistle, faCalendar, faSyncAlt, faPersonSwimming, faLocationDot, faRotate, faGear, faBriefcaseMedical, faCalendarCheck, faComment, faCommentSlash, faAngleRight, faAnglesRight, faInfo, faCircleNotch, faClipboardCheck, faLock, faUsers, faEnvelope, faMagnifyingGlass, faEye, faEyeSlash, faCircleCheck, faBuilding, faRotateRight, faClock, faCircle, faPhone, faCheck, faXmark, faCircleInfo, faStar, faClockRotateLeft, faLifeRing, faPaperPlane, faPencil, faTriangleExclamation, faSignOutAlt, faShareNodes, faUserPlus, faUserSlash, faBell, faUpRightFromSquare, faArrowLeftLong, faSpinnerThird, faCircleExclamation, faRightFromBracket, faSquareCheck, faWaterLadder, faClipboardListCheck, faQuoteLeft, faCircleDashed, faAlarmClock, faChartLineUp, faChartLineDown)

const loadApp = (canonicalUser, setting = {}) => {
  Vue.prototype.$canonicalUser = canonicalUser

  Vue.mixin({
    filters: {
      stripTags
    },
    data () {
      return {
        uid: null,
        ...setting
      }
    },
    methods: {
      _id (prefix) {
        return `${prefix}_${this.uid}`
      },
      splitByNewLine (str) {
        return (str || '').split(/(?:\r\n|\r|\n)/g)
      },
      showSuccessToast (message) {
        this.$bvToast.toast(message, {
          title: 'Gelukt',
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
          appendToast: true
        })
      },
      showErrorToast (error) {
        let errorMessage = error.message
        let isWarning = false
        if (error.response) {
          isWarning = error.response.status < 500
          errorMessage = error.response.data.error.message
        }
        this.$bvToast.toast(errorMessage, {
          title: isWarning ? 'Waarschuwing' : 'Foutmelding',
          // noAutoHide: true,
          auto: 10000,
          variant: isWarning ? 'warning' : 'danger',
          toaster: 'b-toaster-bottom-right',
          appendToast: true
        })
      },
      activityGroupName (group) {
        if (!group) {
          return ''
        } else if (!this.isCustomerPortal) {
          return group.Name
        }

        let name = ''

        if (Array.isArray(group.Warehouse) && group.Warehouse[0]) {
          name = group.Activity.Name + ': ' + group.Warehouse[0].Name
        }

        if (Array.isArray(group.Availability) && group.Availability[0]) {
          let availabilityCandidates = []
          let availabilityExceptionCandidates = []
          group.Availability.forEach(function (element) {
            // check if availability is active in timeslot
            const start = new Date(element.StartDate)
            const end = element.EndDate ? new Date(element.EndDate) : null
            if (start <= Date.now() && (!end || end >= Date.now()) && element.AvailableYN) {
              // pick the most accurate availability in case of exceptions
              if (element.AvailabilityExceptionID) {
                availabilityExceptionCandidates.push(element)
              } else {
                availabilityCandidates.push(element)
              }
            }
          })
          if (availabilityExceptionCandidates.length > 1) {
            return group.Name // this configuration should be impossible, yet handled just in case
          }
          if (availabilityCandidates.length > 1) {
            return group.Name // this configuration should be impossible, yet handled just in case
          }
          let dow = this.dayOfWeekFromAvailability(null)
          if (availabilityExceptionCandidates.length === 1) {
            dow = this.dayOfWeekFromAvailability(availabilityExceptionCandidates[0])
          } else if (availabilityCandidates.length === 1) {
            dow = this.dayOfWeekFromAvailability(availabilityCandidates[0])
          }
          name = name ? `${name}, ${dow}` : dow
        }

        if (Array.isArray(group.Employee) && group.Employee[0]) {
          name = name ? `${name} (${group.Employee[0].Name})` : group.Employee[0].Name
        }

        return name
      },
      dayOfWeekFromAvailability (availability) {
        if (!availability) {
          return 'tijdstip onbekend'
        }

        // 1970-01-01 is a Sunday (0), so add DayOfWeek to get the correct day name
        try {
          const date = new Date(1970, 1, 1, ...availability.TimeStart.split(':').map(str => parseInt(str)))
          date.setDate(date.getDate() + parseInt(availability.DayOfWeek))

          return date.toLocaleString('nl-NL', {
            weekday: 'long',
            hour: 'numeric',
            minute: '2-digit'
          })
        } catch (e) {
          return 'tijdstip onbekend'
        }
      }
    },
    created () {
      this.uid = this._uid
    }
  })

  // library.add(fas, far, fad)
  Vue.component('font-awesome-icon', FontAwesomeIcon)
  Vue.component('font-awesome-layers', FontAwesomeLayers)
  Vue.component('datepicker', Datepicker)

  Vue.use(BootstrapVue)
  Vue.component('VueContentLoading', VueContentLoading)

  Vue.use(VueConfetti)
  Vue.use(VueHtml2Canvas)

  Vue.config.productionTip = false

  function getQueryParams (url) {
    const queryString = url.split('?')[1] || ''
    const params = {}
    const urlSearchParams = new URLSearchParams(queryString)
    urlSearchParams.forEach((value, key) => {
      params[key] = value
    })
    return params
  }

  const queryParams = getQueryParams(window.location.href)

  if (queryParams.locale) {
    store.dispatch('setLocale', queryParams.locale)
  } else {
    store.dispatch('restoreLocaleFromStorage')
  }

  window.addEventListener('load', () => {
    window.addEventListener('online', () => { store.dispatch('appOnline') })
    window.addEventListener('offline', () => { store.dispatch('appOffline') })
  })

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })
  app.$canonicalUser = canonicalUser
  app.$mount('#app')
}

export default loadApp
